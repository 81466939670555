(function () {
	'use strict';
	angular
		.module('common')
		.controller('ModalCtrl', [
			'$scope',
			'$timeout',
			'$translate',
			'utilities',
			ModalCtrl,
		]);

	function ModalCtrl($scope, $timeout, $translate, utilities) {
		$scope.close = close;

		$scope.copySuccess = function (e) {
			var translations = $translate.instant(['Copied to clipboard']);
			e.clearSelection();
			utilities.showMessage(translations['Copied to clipboard']);
		};

		$scope.copyError = function (e) {
			var translations = $translate.instant([
				'Please copy the selected text',
			]);
			utilities.showMessage(
				translations.instant['Please copy the selected text']
			);
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}
	}
})();
